<template>
  <v-container
    class="products-list-page"
    fluid>
    <report-header
      v-model="filter"
      :export-length="items.length"
      :loading="loading"
      @export-click="exportDataToCSV"
      @find="getReport()" />
    <v-row class="products-row">
      <v-col cols="12">
        <report-table
          :headers="headers"
          :branch-headers="branchHeaders"
          :items="items"
          :loading="loading" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MerReportProvider from '@/resources/MerReportProvider'
import { ExportToCsv } from 'export-to-csv'
import ReportHeader from '../components/ReportHeaders.vue'
import ReportTable from '../components/ReportTable.vue'

const MerReportService = new MerReportProvider()
export default {
  components: {
    ReportHeader,
    ReportTable
  },
  data () {
    return {
      filter: {
        dates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
        saleDates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
        search: '',
        searchTags: [],
        tagOperator: 'OR'
      },
      headers: [
        { text: 'Image', value: 'image', align: 'center' },
        { text: 'Product Code', value: 'productCode', align: 'center' },
        { text: 'Sketch ID', value: 'sketchId', align: 'center' },
        { text: 'Designer', value: 'designer', align: 'center' },
        { text: 'Product Name', value: 'productName', align: 'center' },
        { text: 'Type', value: 'type', align: 'center' },
        { text: 'Classification', value: 'classification', align: 'center' },
        { text: 'Stock In', value: 'stockIn', align: 'center' },
        { text: 'Sale', value: 'sale', align: 'center' },
        { text: 'Price', value: 'price', align: 'center' },
        { text: 'Ratio', value: 'ratio', align: 'center' },
        { text: 'NGP', value: 'ngp', align: 'center' },
        { text: 'Color', value: 'color', align: 'center' },
        { text: 'Sale Through', value: 'saleThrough', align: 'center' }
      ],
      items: [],
      branchHeaders: [],
      loading: false
    }
  },
  methods: {
    mapBranchHeader (items) {
      const stats = items.map((item) => item.statsByBranch.map((each) => each.branch))
      const results = []
      stats.forEach((stat) => {
        stat.forEach((each) => {
          const found = results.find((result) => result.id === each.id)
          if (!found) {
            results.push(each)
          }
        })
      })

      return results
    },
    async getReport () {
      try {
        this.loading = true

        const { data } = await MerReportService.getReportColors({
          stockInStart: this.filter.dates[0],
          stockInEnd: this.filter.dates[1],
          saleStart: this.filter.saleDates[0],
          saleEnd: this.filter.saleDates[1],
          search: this.filter.search,
          searchTags: this.filter.searchTags,
          tagOperator: this.filter.tagOperator
        })

        this.items = this.mappedStats(data)
        this.branchHeaders = this.mapBranchHeader(data)
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mappedStats (stats) {
      return stats.map((stat) => {
        const result = { ...stat }
        const allStat = stat.statsByBranch
        allStat.forEach((each) => {
          const stockIn = each.stockIn || 0
          const amount = each.amount || 0
          result[`branch-${each.branch.id}`] = `${amount} / ${stockIn}`
        })

        return result
      })
    },
    exportDataToCSV () {
      const items = []
      const branchHeaders = this.mapBranchHeader(this.items)

      for (const _item of this.items) {
        const eachBranches = Object.keys(_item).filter((key) => key.search('branch-') !== -1)
        const branches = branchHeaders.map((branch) => {
          const result = {
            key: branch.name,
            value: '0 of 0'
          }
          if (eachBranches.includes(`branch-${branch.id}`)) {
            const [amount, stockIn] = _item[`branch-${branch.id}`].split(' / ')
            result.value = `${amount} of ${stockIn}`
          }

          return result
        })

        const item = {
          'Product Code': _item.productCode || '-',
          'Sketch Id': _item.sketchId || '-',
          'Designer': _item.designer || '-',
          'Product Name': _item.productName || '-',
          'Type': _item.type || '-',
          'Stock In': _item.stockIn,
          'Sale': _item.sale,
          'Price': _item.price,
          'Ratio': _item.ratio,
          'NGP': _item.ngp,
          'Color': _item.color,
          'Sale Through': _item.saleThrough
        }

        branches.forEach((branch) => {
          item[branch.key] = branch.value
        })

        items.push(item)
      }
      const options = {
        filename: `merchandise_report_color_(${this.$dayjs().format('YYYY-MM-DD')})`,
        showLabels: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(items)
    }
  }
}
</script>
